.vBaseButton {
  display: flex;
  align-items: center;
  margin-right: 8px;
  & > span {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
}
