.sortsCol {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
}
.vbaseCol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
}
.monitorDropdownContainer {
  margin-top: 8px;
}
