:root {
  --medium-indigo: #5c6bc0;
  --dark-gray: #546e7a;
  --light-red: #f48fb1;
  --yellow: #fff59d;
  --light-orange: #ffcc80;
  --medium-orange: #f79630;
  --light-green: #a5d6a7;
  --medium-purple: #cea8ff;
  --light-purple: #a561ff;
  --light-blue: #90caf9;
  --orange: #ffab91;
  --light-gray: #eeeeee;
  --general-alert: #FEC133;
  --checked: #198753;
}

.App {
  text-align: center;
  font-family: 'Archivo';
}

.noSelect {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* @import "~antd/dist/antd.css"; */
@import "react-vertical-timeline-component/style.min.css";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url(./fonts/Archivo-VariableFont_wdth,wght.ttf) format('woff');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.3);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.Home-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.Center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33vh;
}

.Playback {
  position: absolute;
  top: 230px;
  left: 50px;
  display: 'flex';
}

.Playback > div {
  cursor: pointer
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: minmax(100px, auto);
  row-gap: 4px;
  column-gap: 4px;
  margin: 4px;
  margin-top: 10px;
  max-width: 100%;
}

.grid-container[data-size="large"] {
  grid-template-columns: repeat(3, 1fr);
}

.grid-container[data-size="small"] {
  grid-template-columns: repeat(5, 1fr);
}

.grid-container[data-size="extra-small"] {
  grid-template-columns: repeat(7, 1fr);
  row-gap: 1px;
  column-gap: 1px;
}

.grid-label {
  position: absolute;
  color: white;
  font-size: 15px;
  font-weight: 600;
  left: 4px;
  top: 4px;
  z-index: 2;
}
.grid-time-unchecked-label {
  position: absolute;
  color: rgb(5, 253, 34);
  font-size: 15px;
  font-weight: 600;
  right: 4px;
  top: 4px;
  z-index: 2;
}
.grid-virtual-indicator {
  position: absolute;
  background-color: rgba(165, 97, 255, .2);
  padding: 4px 8px;
  color: var(--light-gray);
  font-size: 15px;
  font-weight: 600;
  left: 4px;
  bottom: 4px;
  z-index: 2;
  border-radius: 8px;
}
.grid-time-unchecked-label-icon {
  background-color: black;
  color: red;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: 0px;
  top: 19px;
  z-index: 2;
  padding: 2px;
}
.grid-label[data-status="night"] {
  background-color: black;
  padding-left: 2px;
  padding-right: 2px;
  opacity: 0.65;
}

.grid-item {
  position: relative;
  background-color: white;
  height: 300px;
  width: 100%;
  border: 1px solid black;
}

.grid-item[data-size="large"] {
  position: relative;
  background-color: white;
  height: 350px;
  width: 100%;
  border: 1px solid black;
}

.grid-item[data-size="small"] {
  position: relative;
  background-color: white;
  height: 300px;
  width: 100%;
  border: 1px solid black;
}

.grid-item[data-size="extra-small"] {
  position: relative;
  background-color: white;
  height: 230px;
  width: 100%;
  border: 1px solid black;
}

.grid-item[data-status="alert"] {
  height: 400px;
  background-color: white;
  width: 100%;
  border: 2px solid lightcoral;
}
#disable {
  border: 2px dashed #10CAF0 ;
}
#fall:not([data-status="offline"]) {
  border: 3px dashed #f44336;
}

.grid-item[data-status="alert"][data-size="large"] {
  height: 440px;
}

.grid-item[data-status="alert"][data-size="extra-small"] {
  height: 320px;
}

.grid-item[data-status="offline"] {
  height: 300px;
  border: 0px;
}

.grid-item[data-status="offline"][data-size="extra-small"] {
  height: 230px;
}

.grid-item2 {
  background-color: blue;
  height: 350px;
  width: 100%;
}

.offline-container {
  display: flex;
  flex: 1;
  background-color: gray;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.private-container {
  display: flex;
  flex: 1;
  background-color: #010842;
  color: white;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
.offline-container[data-size="extra-small"] {
  height: 230px;
}

.info {
  position: absolute;
  bottom: 6px;
  right: 10px;
  text-align: center;
}

.clickable {
  cursor: pointer;
  z-index: 100;
}

.standard-text {
  font-size: 14px;
  text-align: start;
  margin-left: 4px;
}
.options-container {
  display: flex;
  flex-direction: column;
  z-index: 10000;
}
.standard-text[data-size="small"] {
  font-size: 12px;
}

.standard-text[data-size="extra-small"] {
  font-size: 11px;
}

.start-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 4px;
}

.start-column[data-size="small"] {
  margin-left: 2px;
}

.start-column[data-size="extra-small"] {
  margin-left: 1px;
}

.no-es[data-size="extra-small"] {
  display: none;
}

.icon {
  font-size: 14px !important;
}

.section-title {
  margin-top: 12px;
  margin-bottom: 4px;
  text-align: center;
  width: 100%;
}

.bottom-right {
  position: absolute;
  bottom: 2px;
  right: 4px;
}

.bottom-left {
  position: absolute;
  bottom: 2px;
  left: 4px;
}

.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
}

.switch-text {
  margin-right: 4px;
}

#swap-icon {
  font-size: 20px;
}

#swap-icon[data-size="extra-small"] {
  font-size: 18px;
  bottom: 3px;
}

#fall-risk {
  width: 53px;
  height: 17px;
  border-radius: 12px;
  border: 1px solid var(--medium-orange);
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: white;
  background-color: var(--medium-orange);
  bottom: 1px;
  line-height: 17px;
}

#fall-risk[data-size="extra-small"] {
  width: 21px;
  font-size: 8px;
}
#slack-icon {
  font-size: 32px;
  color: blue;
  cursor: pointer;
}
#slack-icon[data-mute='on'] {
  color: red;
}
#fall-risk[data-label="HFR"] {
  background-color: var(--light-red);
}

#note-icon {
  position: absolute;
  height: 18px;
  left: 60px;
  bottom: 1px;
  color: var(--dark-gray);
}

#note-icon[data-size="extra-small"] {
  left: 28px;
}

.status {
  width: 10px;
  height: 10px;
  background-color: var(--light-red);
  border-radius: 5px;
  margin-left: -4px;
  margin-right: 4px;
}

.status[data-status="active"] {
  background-color: var(--light-green);
}

.room-card {
  width: 240px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-container-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px;
  justify-content: center;
  min-height: 1000px;
}

.page-container-col {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4px;
  justify-content: flex-start;
  align-items: center;
  min-height: 1000px;
}

.page-header {
  font-size: 22px;
}

.page-subtitle {
  font-size: 16px;
  font-style: italic;
}

@media only screen and (min-width: 961px) and (max-width: 1250px) {
  .room-card {
    width: 220px;
  }
}

@media only screen and (min-width: 871px) and (max-width: 960px) {
  .room-card {
    width: 180px;
  }
}

@media only screen and (max-width: 870px) {
  .room-card {
    width: 155px;
  }
}

@media only screen and (max-width: 1200px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1690px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media only screen and (min-width: 1691px) and (max-width: 1914px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media only screen and (min-width: 1915px) and (max-width: 2250px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media only screen and (min-width: 2251px) and (max-width: 2530px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(9, 1fr);
  }
}

@media only screen and (min-width: 2531px) {
  .grid-container[data-size="extra-small"] {
    grid-template-columns: repeat(10, 1fr);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** Overridding ant-d styles*/

.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}

.ant-dropdown {
  z-index: 10000;
}

.canvas-wrapper > .ant-modal {
  display: flex;
  width: 1000px;
}

.ant-table-wrapper {
  width: 97vw;
}
/* styling used on the team table */
.teams-table {
  width: 80vw;
}
.team-table-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  width: 100%;
}
.team-table-margin {
  margin-bottom: 2em;
}
.teams-button-container {
  margin-bottom: 10.5px;
}
.team-bread-crumb {
  display: flex;
  justify-content: center;
}
.team-link {
  color: #1890ff;
  cursor: pointer;
}
.team-delete-link {
  color: red;
}
.team-popconfirm {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.room-grid-container {
  display: flex;
  flex-direction: row;
}
.room-grid-first-item {
  width:  20%;
}
.room-grid-second-item {
  width: 80%;
}
.info2 {
  position: absolute;
  bottom: 2px;
  right: 50px;
  font-size: 14px;
}
.info3 {
  position: absolute;
  bottom: 2px;
  right: 40px;
  font-size: 14px;
}
.preview-img-caption ul {
  font-weight: 900;
  background-color: #1890ff;
  color: white;
}
.ant-popconfirm  {
  z-index: 10091;
}
.monitor-room-label-num {
  position: fixed;
  z-index: 10000;
  color: white;
  font-size: 20px;
  width: 100px;
  top: 10px;
  left: 0px;
  right: 0px;
  margin: auto;
  font-weight: bold;
}
.monitor-room-label-fr {
  position: fixed;
  z-index: 10000;
  color: white;
  font-size: 20px;
  width: 100px;
  top: 30px;
  left: 10px;
  right: 0px;
  font-weight: bold;
  background-color: red;
}
.monitor-room-manual-buttons {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;
  font-size: 16px;
  width: 250px;
  top: 40vh;
  left: 15px;
  right: 0px;
  font-weight: bold;
  background-color: white;
  padding: 8px;
}
.monitor-room-promote-resolve-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  color: black;
  background-color: white;
  font-size: 16px;
  width: 250px;
  top: 65vh;
  left: 15px;
  right: 0px;
  font-weight: bold;
  padding: 8px;
}
.monitor-room-promote-resolve-options {
  font-size: 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.time-format-padding {
  padding: 4px;
}
.monitor-room-promote-resolve-options-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.feed-notification-description {
  font-size: 14px;
  font-style: italic;
  margin-bottom: 4px;
}
.feed-notification-playback-arrow-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;
}
.ant-scroll-number {
  color: black;
}
.privacy-mode-container {
  display: flex;
  flex-direction: column;
}
.privacy-mode-eye {
  font-size: 24px;
  margin-right: 4px;
}
.privacy-mode-text {
  font-size: 20px;
  margin-left: 4px;
}
.privacy-mode-timer {
  font-size: 16px;
}
.undo-button {
  position: absolute;
  z-index: 10000;
  top: -35px;
  left: 45%;
}
.undo-button:disabled {
  background-color: var(--dark-gray);
}
.redo-button {
  position: absolute;
  z-index: 10000;
  top: -35px;
  left: 55%;
}
.redo-button:disabled {
  background-color: var(--dark-gray);
}
.region-radio-buttons {
  position: fixed;
  top: 28%;
  right: 20%;
  z-index: 10000;
}
.clear-button {
  position: absolute;
  right: 108px;
  top: 77px;
}
.delete-button {
  position: absolute;
  right: 24px;
  top: 77px;
}
#early_warning:not([data-status="offline"]) {
  border: 2px dashed var(--general-alert);
}
#urgent:not([data-status="offline"]) {
  border: 2px dashed var(--general-alert);
}
.checked {
  color: var(--checked);
}
